@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-style: normal;
  font-weight: 200 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./font/Montserrat-Italic-VariableFont_wght.ttf") format("truetype");
  font-style: italic;
  font-weight: 200 500;
}

:root {
  --working: #aaaaaa;
  --success: #155724;
  --successLighter: #31cd55;
  --bgsuccess: #d4edda;
  --error: #ff3c3c; /* #721c24 */
  --bgerror: #ffcccc;
  --notice: #b2945c;
  --bgnotice: #fff3cd;
  --bg: #fff;
  --bggrey: #f3f4f8;
  --bgblue: #0f4674;
  --bglightblue: #0080bf;
  --grey: #f9f9f9;
  --border: #ddd;
  --blue: #003f5f;
  --boxshadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
  --font-body: "Montserrat", sans-serif;
  --font-heading: "Montserrat", sans-serif;
  --font-color: #292b2e;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  padding: 0;
  margin: 0;
  color: var(--font-color);
  font-weight: 400;
}

body {
  background-color: var(--blue);
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 767px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  position: relative;
}

@media screen and (max-width: 767px) {
  body {
    border: none;
    border: none;
  }
}

a {
  text-decoration: none;
  color: var(--blue);
}

img {
  display: block;
  width: 100%;
  height: auto;
  border: none;
}

.success {
  color: var(--success);
}

.error {
  color: var(--error);
}

.working {
  color: var(--working);
}

table {
  width: 100%;
  border-collapse: collapse;
}

h3 {
  font-weight: 500;
}

th,
td {
  border: 1px solid var(--border);
  padding: 7.5px;
}

th {
  background-color: var(--bggrey);
}

td {
  font-size: 0.95rem;
  padding: 15px 15px 10px 15px;
}

td.center {
  text-align: center;
}

td.right {
  text-align: right;
}

td.total {
  border-top: 1px solid #000;
  text-align: right;
  padding: 15px 15px 12.5px 15px;
  font-size: 1.15rem;
  font-weight: 500;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7.5px 15px 15px 15px;
}

.plus-minus {
  border: 1px solid var(--border);
  padding: 15px 25px 12.5px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-minus > h3 {
  width: 75px;
}

.submit-container {
  width: 100%;
  margin-top: 25px;
}

.submit-container button {
  padding: 12.5px 0 12.5px 0;
  margin: 0 auto;
  width: 100%;
}

.submit-container div {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-container span {
  font-size: 0.95rem;
  font-weight: 500;
  padding-top: 2.5px;
  padding-left: 10px;
}
